import React from "react";

const IconMsb = () => {
  return (
    <svg
      width="204"
      height="72"
      viewBox="0 0 204 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M63.6978 46.9335C63.6978 44.7418 62.8978 42.7494 61.5979 41.1555L43.0986 19.2387C41.4986 17.2462 38.9987 16.0508 36.1989 16.0508C31.2991 16.0508 27.1992 20.0356 27.1992 25.0167C27.1992 27.2084 27.9992 29.2008 29.2991 30.7948L47.7984 52.8112C49.3983 54.8036 51.8982 55.9991 54.6981 55.9991C59.6979 55.8995 63.6978 51.9146 63.6978 46.9335Z"
        fill="url(#paint0_radial_13846_27172)"
      />
      <path
        d="M60.1993 54.0076C61.8993 52.7125 63.0993 50.8197 63.4993 48.8272L68.3993 27.0101C68.9993 24.5195 68.4993 21.7301 66.7993 19.5385C63.7993 15.5536 58.1993 14.8562 54.1993 17.8449C52.4993 19.14 51.2993 21.0328 50.8993 23.0252L46.0993 44.3443L45.9993 44.942C45.3993 47.4325 45.8993 50.2219 47.5993 52.4136C50.5993 56.2989 56.2993 57.0958 60.1993 54.0076Z"
        fill="url(#paint1_radial_13846_27172)"
      />
      <path
        d="M87.1962 46.9335C87.1962 44.7418 86.3962 42.7494 85.0963 41.1555L66.497 19.2387C64.8971 17.2462 62.3972 16.0508 59.5973 16.0508C54.6975 16.0508 50.5977 20.0356 50.5977 25.0167C50.5977 27.2084 51.3976 29.2008 52.6976 30.7948L71.1968 52.8112C72.7968 54.8036 75.2967 55.9991 78.0966 55.9991C83.1964 55.8995 87.1962 51.9146 87.1962 46.9335Z"
        fill="url(#paint2_radial_13846_27172)"
      />
      <path
        d="M27.5 55.9007C32.4706 55.9007 36.5 51.8865 36.5 46.9347C36.5 41.9829 32.4706 37.9688 27.5 37.9688C22.5294 37.9688 18.5 41.9829 18.5 46.9347C18.5 51.8865 22.5294 55.9007 27.5 55.9007Z"
        fill="url(#paint3_radial_13846_27172)"
      />
      <path
        d="M182.794 35.2792L182.594 35.08L182.794 34.8807C184.194 33.1872 184.894 31.0951 184.794 28.9034C184.594 23.9223 180.094 20.1367 174.594 20.1367H162.795C160.795 20.1367 159.295 21.7307 159.295 23.6235V48.5289C159.295 50.5213 160.895 52.0157 162.795 52.0157H174.494C177.394 52.0157 180.094 51.0194 182.194 49.1266C184.294 47.2338 185.494 44.7433 185.494 42.0535C185.594 39.4633 184.594 37.0724 182.794 35.2792ZM166.195 26.4129H175.094C176.794 26.4129 177.894 27.9072 177.894 29.2023C177.894 30.8959 176.394 31.9917 175.094 31.9917H166.195V26.4129ZM175.194 45.5403H166.195V38.3675H175.094C176.494 38.3675 178.694 39.3637 178.694 42.0535C178.694 43.5478 177.494 45.5403 175.194 45.5403Z"
        fill="black"
      />
      <path
        d="M145.095 32.7888C142.395 32.0914 139.195 31.1948 138.995 29.0032C138.895 27.5088 140.095 26.2138 142.095 25.9149C146.095 25.4168 149.595 27.7081 151.495 28.2062C153.395 28.7043 154.895 27.1103 155.095 25.5164C155.395 23.1255 152.995 21.8304 152.095 21.2327C150.595 20.3361 147.895 19.2402 143.895 19.2402C136.795 19.2402 131.895 23.3247 131.895 29.1028C131.895 32.2907 133.095 34.582 135.395 36.2755C139.895 39.6627 148.695 39.2642 148.695 42.751C148.695 45.8392 142.895 47.8317 136.495 43.6476C135.895 43.2491 135.295 42.9502 134.495 42.9502C132.795 42.9502 131.295 44.3449 131.295 46.1381C131.295 46.7358 131.395 47.2339 131.695 47.732L131.595 47.8317L131.695 47.732C131.695 47.8317 131.795 47.8317 131.795 47.9313C132.395 48.8279 133.195 49.6249 134.295 50.2226C135.895 51.3184 138.895 52.6135 143.395 52.6135C147.095 52.6135 150.195 51.7169 152.395 49.9237C154.595 48.1305 155.795 45.5404 155.795 42.4521C155.995 36.4748 150.695 34.2831 145.095 32.7888Z"
        fill="black"
      />
      <path
        d="M124.495 20.0371H122.295C121.095 20.0371 119.896 20.7345 119.396 21.8303L111.996 37.172L104.596 21.8303C103.996 20.7345 102.896 20.0371 101.596 20.0371H99.3963C97.4964 20.0371 95.8965 21.6311 95.8965 23.5239V48.8278C95.8965 51.3183 98.5964 53.2111 101.196 51.7168C102.196 51.2187 102.796 50.1229 102.796 49.027V33.9841L108.996 45.8391C109.596 47.0346 110.796 47.6323 111.996 47.6323C113.196 47.6323 114.396 47.0346 114.996 45.8391L121.195 33.9841V49.027C121.195 50.1229 121.795 51.2187 122.795 51.7168C125.395 53.1115 128.095 51.2187 128.095 48.8278V23.5239C128.095 21.6311 126.495 20.0371 124.495 20.0371Z"
        fill="black"
      />
      <defs>
        <radialGradient
          id="paint0_radial_13846_27172"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(49.6336 27.7401) scale(19.1314 19.0598)"
        >
          <stop offset="0.0184049" stopColor="#6F0000" />
          <stop offset="0.3252" stopColor="#910D11" />
          <stop offset="0.4457" stopColor="#A61519" />
          <stop offset="0.5938" stopColor="#BC1B20" />
          <stop offset="0.7384" stopColor="#CB1E24" />
          <stop offset="0.8762" stopColor="#D52027" />
          <stop offset="1" stopColor="#DA2128" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_13846_27172"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(64.1732 39.2245) scale(17.0568 16.9923)"
        >
          <stop offset="0.0368098" stopColor="#6F0000" />
          <stop offset="0.3006" stopColor="#910D11" />
          <stop offset="0.4172" stopColor="#A01317" />
          <stop offset="0.6776" stopColor="#BE1B21" />
          <stop offset="0.8806" stopColor="#D11F26" />
          <stop offset="1" stopColor="#DA2128" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_13846_27172"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(55.6507 19.8743) scale(34.4343 34.3054)"
        >
          <stop offset="0.3558" stopColor="#DA2128" />
          <stop offset="1" stopColor="#F37021" />
        </radialGradient>
        <radialGradient
          id="paint3_radial_13846_27172"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(22.9517 50.3865) scale(17.8673 17.7997)"
        >
          <stop offset="0.3129" stopColor="#DA2128" />
          <stop offset="0.7914" stopColor="#910D11" />
          <stop offset="1" stopColor="#6F0000" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default IconMsb;
