import React from "react";

const IconShortLoan = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="80" height="80" rx="40" fill="#FEF7F3" />
      <path
        d="M42.1129 34.7172C43.7401 36.3444 43.7401 38.9826 42.1129 40.6098C40.4858 42.237 37.8476 42.237 36.2204 40.6098C34.5932 38.9826 34.5932 36.3444 36.2204 34.7172C37.8476 33.09 40.4858 33.09 42.1129 34.7172Z"
        stroke="#F4600C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M54.9999 43.3034V30.1634C54.9999 28.3217 53.5083 26.8301 51.6666 26.8301H26.6666C24.8249 26.8301 23.3333 28.3217 23.3333 30.1634V45.1634C23.3333 47.0051 24.8249 48.4967 26.6666 48.4967H40.4349"
        stroke="#F4600C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.3333 43.5H31.6666"
        stroke="#F4600C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.6665 31.8335H49.9998"
        stroke="#F4600C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.6233 44.9316C42.505 45.0266 40 47.57 40 50.7133C40 53.915 42.5967 56.5116 45.7983 56.5116C48.1267 56.5116 50.12 55.1316 51.0417 53.1516"
        stroke="#F4600C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.8682 53.1702C47.6665 53.1702 45.0698 50.5735 45.0698 47.3718C45.0698 44.1702 47.6665 41.5735 50.8682 41.5735C54.0715 41.5735 56.6665 44.1702 56.6665 47.3718C56.6665 50.5735 54.0715 53.1702 50.8682 53.1702Z"
        stroke="#F4600C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconShortLoan;
