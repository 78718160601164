import React from "react";

const IconSellingPoint3 = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 31 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.3333 9.33333H22.6667C21.7462 9.33333 21 8.58714 21 7.66667V1"
        stroke="#F4600C"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 9.33333V4.33333C6 2.49238 7.49238 1 9.33333 1H21.2867C22.1707 1.00019 23.0184 1.3515 23.6433 1.97667L28.3567 6.69C28.9818 7.31498 29.3331 8.16268 29.3333 9.04667V27.6667C29.3333 29.5076 27.8409 31 26 31H9.33333C7.49238 31 6 29.5076 6 27.6667V26"
        stroke="#F4600C"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4367 15.9365L8.27002 20.1032L5.77002 17.6032"
        stroke="#F4600C"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9053 9.3335H3.7614C2.23632 9.3335 1 10.5698 1 12.0949V23.2388C1 24.7638 2.23632 26.0002 3.7614 26.0002H14.9053C16.4303 26.0002 17.6667 24.7638 17.6667 23.2388V12.0949C17.6667 10.5698 16.4303 9.3335 14.9053 9.3335Z"
        stroke="#F4600C"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconSellingPoint3;
