import React from "react";

const IconNextArrow = () => {
  return (
    <svg
      width="49"
      height="49"
      viewBox="0 0 49 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.109375" y="0.5" width="48" height="48" rx="24" fill="white" />
      <path
        d="M27.8826 23.5536L22.2293 17.9003C22.1053 17.7753 21.9579 17.6761 21.7954 17.6084C21.6329 17.5407 21.4586 17.5059 21.2826 17.5059C21.1066 17.5059 20.9323 17.5407 20.7699 17.6084C20.6074 17.6761 20.4599 17.7753 20.336 17.9003C20.0876 18.1501 19.9482 18.488 19.9482 18.8403C19.9482 19.1925 20.0876 19.5304 20.336 19.7803L25.056 24.5003L20.336 29.2203C20.0876 29.4701 19.9482 29.808 19.9482 30.1603C19.9482 30.5125 20.0876 30.8504 20.336 31.1003C20.4606 31.2238 20.6083 31.3216 20.7708 31.388C20.9332 31.4543 21.1072 31.4879 21.2826 31.4869C21.4581 31.4879 21.6321 31.4543 21.7945 31.388C21.957 31.3216 22.1047 31.2238 22.2293 31.1003L27.8826 25.4469C28.0076 25.323 28.1068 25.1755 28.1745 25.013C28.2422 24.8505 28.277 24.6763 28.277 24.5003C28.277 24.3242 28.2422 24.15 28.1745 23.9875C28.1068 23.825 28.0076 23.6775 27.8826 23.5536Z"
        fill="#121212"
      />
      <rect
        x="0.609375"
        y="1"
        width="47"
        height="47"
        rx="23.5"
        stroke="black"
        strokeOpacity="0.08"
      />
    </svg>
  );
};

export default IconNextArrow;
