import React from "react";

const IconDiscountVoucher = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.00012207" width="80" height="80" rx="40" fill="#FEF7F3" />
      <path
        d="M26.6666 33.3334H53.3333C55.1749 33.3334 56.6666 34.8251 56.6666 36.6668V53.3334C56.6666 55.1751 55.1749 56.6668 53.3333 56.6668H26.6666C24.8249 56.6668 23.3333 55.1751 23.3333 53.3334V36.6668C23.3333 34.8251 24.8249 33.3334 26.6666 33.3334Z"
        stroke="#F4600C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M42.9462 42.0538C44.5734 43.6809 44.5734 46.3191 42.9462 47.9463C41.319 49.5735 38.6808 49.5735 37.0536 47.9463C35.4265 46.3191 35.4265 43.6809 37.0536 42.0538C38.6808 40.4266 41.319 40.4266 42.9462 42.0538Z"
        stroke="#F4600C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M50 49.1668V40.8334"
        stroke="#F4600C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M30 49.1668V40.8334"
        stroke="#F4600C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M53.3332 28.3334H26.6665"
        stroke="#F4600C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M51.6666 23.3334H28.3333"
        stroke="#F4600C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default IconDiscountVoucher;
