import React, { Fragment, lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { IconMsb } from "lib/icons";
import { TourProvider } from "@reactour/tour";
import { Button, Typography } from "antd-msb";

// Can still subscribe to the store
// store.subscribe(() => console.log("getState ", store.getState()))

// replace console.* for disable log on production
// console.log = () => {};
// console.error = () => {};
// console.debug = () => {};
const App = lazy(() => import("app"));

const stepsTour = [
  {
    selector: ".first-step",
    content: () => (
      <Typography.Text fontWeight="semibold">
        Bấm để xem hướng dẫn chi tiết chuẩn bị hồ sơ
      </Typography.Text>
    ),
  },
  {
    selector: ".second-step",
    content: () => (
      <Fragment>
        <Typography.Text version={2} fontWeight="semibold">
          Bấm để tải hồ sơ
        </Typography.Text>
        <br />
        <Typography.Text version={2} size="small">
          Hồ sơ cung cấp là bản mới nhất và có dung lượng không quá 30 MB
        </Typography.Text>
      </Fragment>
    ),
  },
  {
    selector: ".third-step",
    content: () => (
      <Typography.Text version={2} fontWeight="semibold">
        Bấm để hoàn thành nộp hồ sơ và chuyển đến bước tiếp theo
      </Typography.Text>
    ),
  },
];

ReactDOM.render(
  <Suspense
    fallback={
      <div
        style={{
          width: 204,
          height: 72,
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          margin: "auto",
        }}
      >
        <IconMsb />
      </div>
    }
  >
    <TourProvider
      disableDotsNavigation
      steps={stepsTour}
      showDots={false}
      showCloseButton={false}
      onClickMask={() => false}
      showBadge={false}
      disableInteraction
      styles={{
        popover: (base) => ({
          ...base,
          borderRadius: "12px",
          padding: "16px",
          width: "280px",
        }),
        controls: (base) => ({
          ...base,
          marginTop: 24,
          justifyContent: "flex-end",
        }),
      }}
      prevButton={({ currentStep, setIsOpen }) => {
        return currentStep === 2 ? (
          ""
        ) : (
          <Button type="text" onClick={() => {
            localStorage.setItem("hideTour", "1");
            setIsOpen(false)
          }}>
            Bỏ qua
          </Button>
        );
      }}
      nextButton={({ currentStep, setCurrentStep, setIsOpen }) => {
        return (
          <Button
            type="text"
            onClick={() => {
              setCurrentStep(currentStep + 1);
              if (currentStep === 2) {
                localStorage.setItem("hideTour", "1");
                setIsOpen(false);
              }
            }}
          >
            <Typography.Text type="brand">
              {currentStep < 2 ? "Tiếp tục" : "Xong"}
              {`(${currentStep + 1}/${stepsTour.length})`}
            </Typography.Text>
          </Button>
        );
      }}
    >
      <App />
    </TourProvider>
  </Suspense>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
