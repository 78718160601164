import React from "react";

const IconLongLoan = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.00012207" width="80" height="80" rx="40" fill="#FEF7F3" />
      <path
        d="M39.9999 46.5001H26.4999C24.7499 46.5001 23.3333 45.0835 23.3333 43.3335V28.1668C23.3333 26.4168 24.7499 25.0001 26.4999 25.0001H51.8333C53.5833 25.0001 54.9999 26.4168 54.9999 28.1668V36.6668"
        stroke="#F4600C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M41.9648 33.2851C43.5098 34.8301 43.5098 37.3368 41.9648 38.8835C40.4198 40.4285 37.9132 40.4285 36.3665 38.8835C34.8215 37.3385 34.8215 34.8318 36.3665 33.2851C37.9132 31.7385 40.4198 31.7385 41.9648 33.2851Z"
        stroke="#F4600C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M56.6667 48.0001V43.3335M56.6667 43.3335C56.6667 42.0435 54.0533 41.0001 50.8333 41.0001C47.6133 41.0001 45.0017 42.0451 45 43.3335V52.6668C45.0017 53.9551 47.6117 55.0001 50.8333 55.0001C54.055 55.0001 56.665 53.9551 56.6667 52.6668V43.3335Z"
        stroke="#F4600C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M45.0015 43.3335C45.0015 44.6218 47.6131 45.6668 50.8348 45.6668C54.0565 45.6668 56.6665 44.6218 56.6665 43.3335"
        stroke="#F4600C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M45.0015 48.0001C45.0015 49.2885 47.6131 50.3335 50.8348 50.3335C54.0565 50.3335 56.6681 49.2885 56.6681 48.0001"
        stroke="#F4600C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M29.9999 41.6667H28.3333"
        stroke="#F4600C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M49.9999 30.0001H48.3333"
        stroke="#F4600C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default IconLongLoan;
