import React from "react";

const IconSellingPoint2 = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.57812 17.8906H14.0081"
        stroke="#F4600C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4092 10.1203C15.3284 10.6532 15.2881 11.1914 15.2891 11.7303C15.2922 14.3632 16.2615 16.9033 18.013 18.8691C19.7644 20.8349 22.1762 22.0895 24.7913 22.3951C27.4063 22.7007 30.0427 22.036 32.2002 20.5271C34.3577 19.0181 35.8865 16.7699 36.4966 14.2087C37.1067 11.6475 36.7556 8.95144 35.51 6.63188C34.2644 4.31233 32.2108 2.53057 29.7388 1.62447C27.2668 0.718362 24.5482 0.750928 22.0986 1.71602C19.6491 2.68111 17.6389 4.5116 16.4492 6.86034"
        stroke="#F4600C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.8582 10.1201H7.57812"
        stroke="#F4600C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.082 6.89062H10.1719"
        stroke="#F4600C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9792 13.3398H2.85938"
        stroke="#F4600C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.0586 4.80078V6.0708"
        stroke="#F4600C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.0586 17.1201V18.6601"
        stroke="#F4600C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.1016 15.1805C24.2516 16.3605 28.9417 17.0804 29.4417 14.2904C30.1017 10.5804 22.6617 13.0205 23.4417 8.78046C23.8817 6.28046 28.2915 6.88046 29.0715 7.78046"
        stroke="#F4600C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.927 28.6404L34.0269 24.5504C34.3 24.3921 34.6016 24.2893 34.9146 24.2476C35.2275 24.2059 35.5455 24.2263 35.8506 24.3076C36.1556 24.3888 36.4417 24.5293 36.6924 24.7211C36.9431 24.9129 37.1538 25.1522 37.312 25.4254C37.4703 25.6985 37.5731 26.0002 37.6147 26.3131C37.6564 26.626 37.6359 26.9441 37.5547 27.2491C37.4734 27.5542 37.3329 27.8402 37.1411 28.0909C36.9493 28.3416 36.7102 28.5521 36.437 28.7104L26.0669 34.6204C25.5829 34.8974 25.0345 35.0422 24.4768 35.0404H16.6069C16.0254 35.0414 15.4552 35.2004 14.957 35.5004L13.0469 36.6504"
        stroke="#F4600C"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.397 29.7006H24.5469C25.1826 29.698 25.7914 29.4443 26.241 28.9948C26.6905 28.5453 26.9444 27.9363 26.947 27.3006V27.3006C26.9444 26.664 26.6909 26.054 26.2417 25.6029C25.7925 25.1518 25.1835 24.8959 24.5469 24.8906H15.8369C15.248 24.8936 14.671 25.056 14.167 25.3606L8.79688 28.6306"
        stroke="#F4600C"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.50781 29.1703L7.17773 27.5703L13.5779 37.7103L11.9878 38.7803"
        stroke="#F4600C"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconSellingPoint2;
