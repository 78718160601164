import React from "react";

const IconPrevArrow = () => {
  return (
    <svg
      width="49"
      height="49"
      viewBox="0 0 49 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.109375" y="0.5" width="48" height="48" rx="24" fill="white" />
      <path
        d="M23.1625 24.5003L27.8825 19.7803C28.1308 19.5304 28.2702 19.1925 28.2702 18.8403C28.2702 18.488 28.1308 18.1501 27.8825 17.9003C27.7585 17.7753 27.6111 17.6761 27.4486 17.6084C27.2861 17.5407 27.1118 17.5059 26.9358 17.5059C26.7598 17.5059 26.5855 17.5407 26.423 17.6084C26.2606 17.6761 26.1131 17.7753 25.9891 17.9003L20.3358 23.5536C20.2108 23.6775 20.1116 23.825 20.0439 23.9875C19.9763 24.15 19.9414 24.3242 19.9414 24.5003C19.9414 24.6763 19.9763 24.8505 20.0439 25.013C20.1116 25.1755 20.2108 25.323 20.3358 25.4469L25.9891 31.1669C26.1137 31.2905 26.2615 31.3883 26.4239 31.4546C26.5864 31.521 26.7603 31.5546 26.9358 31.5536C27.1113 31.5546 27.2852 31.521 27.4477 31.4546C27.6101 31.3883 27.7579 31.2905 27.8825 31.1669C28.1308 30.9171 28.2702 30.5792 28.2702 30.2269C28.2702 29.8747 28.1308 29.5367 27.8825 29.2869L23.1625 24.5003Z"
        fill="#121212"
      />
      <rect
        x="0.609375"
        y="1"
        width="47"
        height="47"
        rx="23.5"
        stroke="black"
        strokeOpacity="0.08"
      />
    </svg>
  );
};

export default IconPrevArrow;
