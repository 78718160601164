import React from "react";

const IconCreditLoan = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="80" height="80" rx="40" fill="#FEF7F3" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.6681 34.1669C28.6903 32.1712 28.9853 31.2702 29.466 30.7949C29.9701 30.2965 30.9457 30 33.1429 30H46.8457C49.0496 30 50.0285 30.2966 50.5341 30.7956C51.0156 31.2708 51.3106 32.1712 51.3328 34.1669H40.0005H28.6681ZM26.6667 35.1669V34.4145C26.6667 32.255 26.9165 30.5032 28.0598 29.3727C29.1992 28.2462 30.9617 28 33.1429 28H46.8457C49.0326 28 50.798 28.2461 51.9389 29.3721C53.0845 30.5026 53.3342 32.2551 53.3342 34.4145V35.1669V38.2256C53.3342 38.7779 52.8865 39.2256 52.3342 39.2256C51.7819 39.2256 51.3342 38.7779 51.3342 38.2256V36.1669H28.6667V44.5528C28.6667 46.7225 28.9658 47.6779 29.466 48.1724C29.9701 48.6708 30.9457 48.9673 33.1429 48.9673H46.858C49.0553 48.9673 50.0309 48.6708 50.5349 48.1724C51.0351 47.6779 51.3342 46.7225 51.3342 44.5528V43.1714C51.3342 42.6192 51.7819 42.1714 52.3342 42.1714C52.8865 42.1714 53.3342 42.6192 53.3342 43.1714V44.5528C53.3342 46.7123 53.0844 48.4642 51.9411 49.5946C50.8017 50.7212 49.0393 50.9673 46.858 50.9673H33.1429C30.9617 50.9673 29.1992 50.7212 28.0598 49.5946C26.9165 48.4642 26.6667 46.7123 26.6667 44.5528V35.1669ZM32.6002 44.0338C32.0479 44.0338 31.6002 44.4815 31.6002 45.0338C31.6002 45.5861 32.0479 46.0338 32.6002 46.0338H35.0679C35.6202 46.0338 36.0679 45.5861 36.0679 45.0338C36.0679 44.4815 35.6202 44.0338 35.0679 44.0338H32.6002ZM37.1504 45.0338C37.1504 44.4815 37.5981 44.0338 38.1504 44.0338H43.0839C43.6362 44.0338 44.0839 44.4815 44.0839 45.0338C44.0839 45.5861 43.6362 46.0338 43.0839 46.0338H38.1504C37.5981 46.0338 37.1504 45.5861 37.1504 45.0338Z"
        fill="#F4600C"
      />
    </svg>
  );
};

export default IconCreditLoan;
