import React from "react";

const IconLetterCredit = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.00012207" width="80" height="80" rx="40" fill="#FEF7F3" />
      <path
        d="M30 30.0468H50"
        stroke="#F4600C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M33.3333 25.0468H46.6666"
        stroke="#F4600C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M51.6667 35.0001H28.3333C26.4917 35.0001 25 36.4918 25 38.3335V51.6668C25 53.5085 26.4917 55.0001 28.3333 55.0001H51.6667C53.5083 55.0001 55 53.5085 55 51.6668V38.3335C55 36.4918 53.5083 35.0001 51.6667 35.0001Z"
        stroke="#F4600C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M43.3333 40.0001H49.9999V45.0001H43.3333V40.0001Z"
        stroke="#F4600C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M30 45.0001H35"
        stroke="#F4600C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M30 50.0001H38.3333"
        stroke="#F4600C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default IconLetterCredit;
