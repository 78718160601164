import React from "react";

const IconSuccess = (props: { width?: number; height?: number }) => {
  const { width = 54, height = 54 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 54 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27 0.333328C12.3333 0.333328 0.333313 12.3333 0.333313 27C0.333313 41.6667 12.3333 53.6667 27 53.6667C41.6666 53.6667 53.6666 41.6667 53.6666 27C53.6666 12.3333 41.6666 0.333328 27 0.333328ZM38.2 22.4667L25.4 35.2667C24.3333 36.3333 22.7333 36.3333 21.6666 35.2667L15.8 29.4C14.7333 28.3333 14.7333 26.7333 15.8 25.6667C16.8666 24.6 18.4666 24.6 19.5333 25.6667L23.5333 29.6667L34.4666 18.7333C35.5333 17.6667 37.1333 17.6667 38.2 18.7333C39.2667 19.8 39.2667 21.4 38.2 22.4667Z"
        fill="#39B271"
      />
    </svg>
  );
};

export default IconSuccess;
