import React from "react";

const IconOverDraftLoan = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="80" height="80" rx="40" fill="#FEF7F3" />
      <path
        d="M30 45V48.3333"
        stroke="#F4600C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M44.1667 45V48.3333"
        stroke="#F4600C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M28.3333 55H45.6667C47.5083 55 49 53.5084 49 51.6667V41.6667C49 39.825 47.5083 38.3334 45.6667 38.3334H28.3333C26.4917 38.3334 25 39.825 25 41.6667V51.6667C25 53.5084 26.4917 55 28.3333 55Z"
        stroke="#F4600C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M38.8517 44.8984C39.8284 45.875 39.8284 47.4584 38.8517 48.4334C37.8751 49.4084 36.2917 49.41 35.3167 48.4334C34.3401 47.4567 34.3401 45.8734 35.3167 44.8984C36.2934 43.9234 37.8751 43.9234 38.8517 44.8984Z"
        stroke="#F4600C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M49.0001 42.1601L52.5284 41.2151C54.3084 40.7384 55.3634 38.9067 54.8834 37.1284L52.2818 27.4667C51.8034 25.6934 49.9801 24.6401 48.2051 25.1134L31.4668 29.5734C29.6851 30.0484 28.6284 31.8784 29.1068 33.6584L30.3618 38.3334"
        stroke="#F4600C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default IconOverDraftLoan;
