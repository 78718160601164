import React from "react";

const IconInterestRate = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 3V6"
        stroke="#1D2939"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5 3V6"
        stroke="#1D2939"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 21H6C5.20435 21 4.44129 20.6839 3.87868 20.1213C3.31607 19.5587 3 18.7956 3 18V7.5C3 6.70435 3.31607 5.94129 3.87868 5.37868C4.44129 4.81607 5.20435 4.5 6 4.5H18C18.7956 4.5 19.5587 4.81607 20.1213 5.37868C20.6839 5.94129 21 6.70435 21 7.5V10"
        stroke="#1D2939"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.749 18.25L18.249 15.75"
        stroke="#1D2939"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.6348 18.5599C18.6419 18.5669 18.6468 18.5758 18.6488 18.5855C18.6508 18.5953 18.6498 18.6054 18.6461 18.6146C18.6423 18.6238 18.6359 18.6316 18.6277 18.6372C18.6194 18.6427 18.6097 18.6457 18.5998 18.6457C18.5899 18.6457 18.5802 18.6427 18.5719 18.6372C18.5637 18.6316 18.5573 18.6238 18.5535 18.6146C18.5498 18.6054 18.5488 18.5953 18.5508 18.5855C18.5529 18.5758 18.5577 18.5669 18.5648 18.5599C18.5742 18.5508 18.5867 18.5457 18.5998 18.5457C18.6129 18.5457 18.6255 18.5508 18.6348 18.5599"
        stroke="#1D2939"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.9996 17C21.9975 18.3233 21.4701 19.5916 20.5333 20.5261C19.5964 21.4607 18.3269 21.9851 17.0036 21.984C15.6804 21.9829 14.4117 21.4565 13.4763 20.5205C12.541 19.5844 12.0156 18.3153 12.0156 16.992C12.0156 15.6687 12.541 14.3996 13.4763 13.4635C14.4117 12.5275 15.6804 12.0011 17.0036 12C18.3269 11.9989 19.5964 12.5233 20.5333 13.4579C21.4701 14.3924 21.9975 15.6607 21.9996 16.984V17Z"
        stroke="#1D2939"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3655 15.4401C15.3582 15.4305 15.3547 15.4186 15.3556 15.4066C15.3564 15.3946 15.3616 15.3833 15.3701 15.3748C15.3786 15.3662 15.3899 15.3611 15.4019 15.3602C15.4139 15.3594 15.4258 15.3629 15.4355 15.3701C15.4446 15.3795 15.4498 15.392 15.4498 15.4051C15.4498 15.4182 15.4446 15.4308 15.4355 15.4401C15.4261 15.4493 15.4136 15.4544 15.4005 15.4544C15.3874 15.4544 15.3748 15.4493 15.3655 15.4401"
        stroke="#1D2939"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconInterestRate;
