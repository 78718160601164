import React from "react";

const IconDollar = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 4H6C3.79086 4 2 5.79086 2 8V16C2 18.2091 3.79086 20 6 20H18C20.2091 20 22 18.2091 22 16V8C22 5.79086 20.2091 4 18 4Z"
        stroke="#1D2939"
        strokeWidth="1.5"
      />
      <path
        d="M14.75 10C14.75 10.1989 14.671 10.3897 14.5303 10.5303C14.3897 10.671 14.1989 10.75 14 10.75C13.8011 10.75 13.6103 10.671 13.4697 10.5303C13.329 10.3897 13.25 10.1989 13.25 10H14.75ZM12 11.25C12.1989 11.25 12.3897 11.329 12.5303 11.4697C12.671 11.6103 12.75 11.8011 12.75 12C12.75 12.1989 12.671 12.3897 12.5303 12.5303C12.3897 12.671 12.1989 12.75 12 12.75V11.25ZM13.25 10C13.25 9.31 12.69 8.75 12 8.75V7.25C12.7293 7.25 13.4288 7.53973 13.9445 8.05546C14.4603 8.57118 14.75 9.27065 14.75 10H13.25ZM12 8.75C11.31 8.75 10.75 9.31 10.75 10H9.25C9.25 9.27065 9.53973 8.57118 10.0555 8.05546C10.5712 7.53973 11.2707 7.25 12 7.25V8.75ZM10.75 10C10.75 10.69 11.31 11.25 12 11.25V12.75C11.2707 12.75 10.5712 12.4603 10.0555 11.9445C9.53973 11.4288 9.25 10.7293 9.25 10H10.75Z"
        fill="#1D2939"
      />
      <path
        d="M12 12.75C11.8011 12.75 11.6103 12.671 11.4697 12.5303C11.329 12.3897 11.25 12.1989 11.25 12C11.25 11.8011 11.329 11.6103 11.4697 11.4697C11.6103 11.329 11.8011 11.25 12 11.25V12.75ZM9.25 14C9.25 13.8011 9.32902 13.6103 9.46967 13.4697C9.61032 13.329 9.80109 13.25 10 13.25C10.1989 13.25 10.3897 13.329 10.5303 13.4697C10.671 13.6103 10.75 13.8011 10.75 14H9.25ZM13.25 14C13.25 13.31 12.69 12.75 12 12.75V11.25C12.7293 11.25 13.4288 11.5397 13.9445 12.0555C14.4603 12.5712 14.75 13.2707 14.75 14H13.25ZM10.75 14C10.75 14.69 11.31 15.25 12 15.25V16.75C11.2707 16.75 10.5712 16.4603 10.0555 15.9445C9.53973 15.4288 9.25 14.7293 9.25 14H10.75ZM12 15.25C12.69 15.25 13.25 14.69 13.25 14H14.75C14.75 14.7293 14.4603 15.4288 13.9445 15.9445C13.4288 16.4603 12.7293 16.75 12 16.75V15.25Z"
        fill="#1D2939"
      />
      <path
        d="M12 6.5V8"
        stroke="#1D2939"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 16V17.5"
        stroke="#1D2939"
        stroke-width="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconDollar;
