import React from "react";

const IconSellingPoint4 = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.3335 35H6.66683C4.82516 35 3.3335 33.5083 3.3335 31.6666V29.39C3.3335 27.5483 4.82516 26.0566 6.66683 26.0566H33.3335C35.1752 26.0566 36.6668 27.5483 36.6668 29.39V31.6666C36.6668 33.5083 35.1752 35 33.3335 35Z"
        stroke="#F4600C"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.7765 6.01195C17.1257 7.36121 17.1257 9.5488 15.7765 10.898C14.4272 12.2473 12.2396 12.2473 10.8904 10.898C9.5411 9.54878 9.5411 7.3612 10.8904 6.01195C12.2396 4.66268 14.4272 4.66268 15.7765 6.01195Z"
        stroke="#F4600C"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.77197 26.0565L7.87031 19.4532C8.13697 17.8448 9.52864 16.6665 11.1586 16.6665H15.5403C17.157 16.6665 18.5403 17.8265 18.822 19.4182L20.0003 26.0565"
        stroke="#F4600C"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.4717 26.0565V18.3332C24.4717 17.4132 25.2183 16.6665 26.1383 16.6665H31.6667C32.5867 16.6665 33.3333 17.4132 33.3333 18.3332V26.0565"
        stroke="#F4600C"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconSellingPoint4;
