import React from "react";

const IconWarning = () => {
  return (
    <svg
      width="54"
      height="54"
      viewBox="0 0 54 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.0002 0.333496C12.3335 0.333496 0.333496 12.3335 0.333496 27.0002C0.333496 41.6668 12.3335 53.6668 27.0002 53.6668C41.6668 53.6668 53.6668 41.6668 53.6668 27.0002C53.6668 12.3335 41.6668 0.333496 27.0002 0.333496ZM27.0002 40.3335C25.4002 40.3335 24.3335 39.2668 24.3335 37.6668C24.3335 36.0668 25.4002 35.0002 27.0002 35.0002C28.6002 35.0002 29.6668 36.0668 29.6668 37.6668C29.6668 39.2668 28.6002 40.3335 27.0002 40.3335ZM29.6668 27.0002C29.6668 28.6002 28.6002 29.6668 27.0002 29.6668C25.4002 29.6668 24.3335 28.6002 24.3335 27.0002V16.3335C24.3335 14.7335 25.4002 13.6668 27.0002 13.6668C28.6002 13.6668 29.6668 14.7335 29.6668 16.3335V27.0002Z"
        fill="#FFA60D"
      />
    </svg>
  );
};

export default IconWarning;
