import React from "react";

const IconShophouse = () => {
  return (
    <svg
      width="18"
      height="21"
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 7.00049L11 1.74049C10.45 1.24854 9.7379 0.976563 8.99997 0.976562C8.26204 0.976563 7.54999 1.24854 6.99997 1.74049L0.999973 7.00049C0.682341 7.28457 0.428873 7.63305 0.256445 8.02274C0.0840165 8.41243 -0.00341463 8.83438 -2.72762e-05 9.26049V18.0005C-2.72762e-05 18.7961 0.316043 19.5592 0.878652 20.1218C1.44126 20.6844 2.20432 21.0005 2.99997 21.0005H15C15.7956 21.0005 16.5587 20.6844 17.1213 20.1218C17.6839 19.5592 18 18.7961 18 18.0005V9.25049C18.0019 8.82606 17.9138 8.40603 17.7414 8.01818C17.5691 7.63032 17.3163 7.28345 17 7.00049ZM11 19.0005H6.99997V14.0005C6.99997 13.7353 7.10533 13.4809 7.29287 13.2934C7.4804 13.1059 7.73476 13.0005 7.99997 13.0005H9.99997C10.2652 13.0005 10.5195 13.1059 10.7071 13.2934C10.8946 13.4809 11 13.7353 11 14.0005V19.0005ZM16 18.0005C16 18.2657 15.8946 18.5201 15.7071 18.7076C15.5195 18.8951 15.2652 19.0005 15 19.0005H13V14.0005C13 13.2048 12.6839 12.4418 12.1213 11.8792C11.5587 11.3166 10.7956 11.0005 9.99997 11.0005H7.99997C7.20432 11.0005 6.44126 11.3166 5.87865 11.8792C5.31604 12.4418 4.99997 13.2048 4.99997 14.0005V19.0005H2.99997C2.73476 19.0005 2.4804 18.8951 2.29287 18.7076C2.10533 18.5201 1.99997 18.2657 1.99997 18.0005V9.25049C2.00015 9.10851 2.03057 8.96819 2.08919 8.83887C2.14781 8.70956 2.2333 8.5942 2.33997 8.50049L8.33997 3.25049C8.52246 3.09018 8.75706 3.00176 8.99997 3.00176C9.24288 3.00176 9.47748 3.09018 9.65997 3.25049L15.66 8.50049C15.7666 8.5942 15.8521 8.70956 15.9108 8.83887C15.9694 8.96819 15.9998 9.10851 16 9.25049V18.0005Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconShophouse;
