import React from "react";

const IconBankGuarantee = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.00012207" width="80" height="80" rx="40" fill="#FEF7F3" />
      <g clip-path="url(#clip0_3528_163905)">
        <path
          d="M30 55.0001H26.6667C25.7467 55.0001 25 54.2534 25 53.3334V43.3334C25 42.4134 25.7467 41.6667 26.6667 41.6667H30C30.92 41.6667 31.6667 42.4134 31.6667 43.3334V53.3334C31.6667 54.2534 30.92 55.0001 30 55.0001Z"
          stroke="#F4600C"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M41.6667 48.3334H45.5551C46.2767 48.3334 46.9784 48.1001 47.5551 47.6667L51.5817 44.6467C52.5867 43.8934 53.9934 43.9934 54.8817 44.8817C55.8684 45.8684 55.8684 47.4667 54.8817 48.4517L51.4267 51.9067C50.4967 52.8367 49.3101 53.4717 48.0201 53.7301L43.1367 54.7067C42.1684 54.9001 41.1701 54.8767 40.2117 54.6367L35.7951 53.5334C35.2667 53.4001 34.7251 53.3334 34.1801 53.3334H31.6667"
          stroke="#F4600C"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M41.6667 48.3334H44.1417C45.5367 48.3334 46.6667 47.2034 46.6667 45.8084V45.3034C46.6667 44.1451 45.8784 43.1351 44.7551 42.8551L40.9351 41.9001C40.3134 41.7451 39.6767 41.6667 39.0367 41.6667C37.4917 41.6667 35.9801 42.1234 34.6951 42.9817L31.6667 45.0001"
          stroke="#F4600C"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M48.6147 28.5922C50.4618 30.4394 50.4618 33.4342 48.6147 35.2814C46.7675 37.1286 43.7726 37.1286 41.9254 35.2814C40.0782 33.4342 40.0782 30.4394 41.9254 28.5922C43.7726 26.745 46.7675 26.745 48.6147 28.5922Z"
          stroke="#F4600C"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M44.385 27.2918C44.23 26.3502 43.8 25.4452 43.0733 24.7185C41.2267 22.8718 38.2317 22.8718 36.385 24.7185C34.5383 26.5652 34.5383 29.5602 36.385 31.4068C37.535 32.5568 39.1283 32.9885 40.6133 32.7068"
          stroke="#F4600C"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3528_163905">
          <rect
            width="40"
            height="40"
            fill="white"
            transform="translate(20 20.0001)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconBankGuarantee;
