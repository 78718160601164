import React from "react";

const IconSponsoredItem = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.00012207" width="80" height="80" rx="40" fill="#FEF7F3" />
      <path
        d="M38.3333 46.5001H26.4999C24.7499 46.5001 23.3333 45.0835 23.3333 43.3335V28.1668C23.3333 26.4168 24.7499 25.0001 26.4999 25.0001H51.8333C53.5833 25.0001 54.9999 26.4168 54.9999 28.1668V36.6668"
        stroke="#F4600C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M41.9648 33.2851C43.5098 34.8301 43.5098 37.3368 41.9648 38.8835C40.4198 40.4285 37.9132 40.4285 36.3665 38.8835C34.8215 37.3385 34.8215 34.8318 36.3665 33.2851C37.9132 31.7385 40.4198 31.7385 41.9648 33.2851Z"
        stroke="#F4600C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M49.9999 55.0001C46.3183 55.0001 43.3333 52.0151 43.3333 48.3334C43.3333 44.6517 46.3183 41.6667 49.9999 41.6667C53.6833 41.6667 56.6666 44.6517 56.6666 48.3334C56.6666 52.0151 53.6833 55.0001 49.9999 55.0001Z"
        stroke="#F4600C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M29.9999 41.6667H28.3333"
        stroke="#F4600C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M49.9999 30.0001H48.3333"
        stroke="#F4600C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M52.9633 46.8518L49.26 50.5551L47.0366 48.3335"
        stroke="#F4600C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default IconSponsoredItem;
